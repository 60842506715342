.table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 0px 25px #00000026;
  border-radius: 25px;
  overflow: hidden;
  margin-top: 20px;
  border: 2px solid #ccc; /* Add border around the table */
  font-size: 16px;
  margin-left: 1%;
}

td {
  border-right: none; /* Remove right border */
  width: max-content;

  border-bottom: 1px solid #ddd; /* Add light border for rows */
}
th {
  border-right: none;
  padding: 1.5%;
  text-align: left;

  border-bottom: 1px solid #ddd;
}

th:last-child,
td:last-child {
  border-right: none; /* Remove right border for the last column */
}

.user-profile-cell {
  display: flex;
  align-items: center;
}

.user-profile-cell img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .table {
    overflow-x: auto; /* Add horizontal scroll for small screens */
  }
}
.table_div {
  padding: 3%;
}
