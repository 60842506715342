.imgdiv {
  width: 100%;
  height: 720px;
}
.react-multiple-carousel__arrow--right {
  right: 0px !important;
}
.react-multiple-carousel__arrow--left {
  left: 0px !important;
}
.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: white !important;
  min-width: 43px;
  border: 1px solid #6083f7 !important;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}
.react-multiple-carousel__arrow::before {
  color: #6083f7 !important;
  font-weight: bold !important ;
}

.left_mc {
  height: 200px;
  /* border: 1px solid #161414; */
  padding-top: 0px;
  margin-top: 0px;
  width: 25%;
  /* padding-left: 10px; */
}
.text-with-background {
  display: flex;
  align-items: center;
  margin: 0px;
  width: 100%;
  padding: -10px;
}

.consult {
  text-decoration: underline;
  font-style: italic;
  font-size: 21px;

  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.blue-background {
  background-color: #6083f7;
  color: white;
  padding: 0px;
  width: 100%;
  padding-left: -10px;
  font-size: 30px;
  /* font-family:; */
}
.main-container {
  height: 250px;

  /* border: 1px solid #161414; */
  display: flex;
  flex-direction: row;

  justify-content: center;

  align-items: center;

  padding-top: 50px;
}
.home_container {
  
}

.category_grid-item {
  text-align: center;
  align-items: center;
  height: 190px;
  margin-inline: 10px;
  background: #ffffff 0% 0%;
  /* border:1px solid black; */
  box-shadow: 0px 0px 12px #00000029;
  border-radius: 10px;
  opacity: 1;
  position: relative;
  margin: 10px;
}
.category_grid-item a {
  position: absolute;
  z-index: 999;
  bottom: 10px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-size: 14px !important;
}
/* style={{height:"150px",padding:"10px"}} */
.category_image-container {
  height: 150px; /* Set a fixed height for the image container */
  overflow: hidden;
  padding: 10px;
  display: flex;
}
.word-container p {
  font-size: 14px;
  text-decoration: none;
  font-style: italic;
}
/* style={{height:"100%",objectFit:"initial", width:"60%"}} */
.category_img {
  width: auto;
  height: 100px;
  max-width: 96%;
  object-fit: cover;
  margin: auto;
}

/* HorizontalGrid.css */
.home_headline {

  font-size: 30px;
}
.topagent {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 50px;
}

.horizontal-grid-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  margin-inline: 10%;
}

.grid-item {
  flex: 1;

  text-align: center;
  width: 250px;
}

.image-container {
  width: 100%;
  height: 100%;
  min-height: 200px;
  border-radius: 10px; /* Set a fixed height for the image container */
  /* padding: 1%; */
}

.img1 {
  height: 100%;

  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.word-container {
  margin-top: 10px;
  font-weight: 500;
  font-size: 21px;
  color: black;
}
.word-container p{font-size: 16px !important;  font-weight: 500; text-decoration: underline;}
p {
  margin: 0;
}

.find_div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  
}
.find_border h2{margin: 0px 0px 25px 0px; padding: 0px;;}
.find_border {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-shadow: 0px 0px 12px #00000029;
  border-radius: 10px;
  
  margin: 25px 0px 30px 0px;

  width: 100%;
  padding: 28px 30px;
}
.find_firstdiv {
  width: 100%;
  /* Add your desired background color */
  text-align: center;
  padding: 20px;

  font-size: 30px;
}

.find_secdiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 1%;

  width: 100%;
  /* padding: 20px; */
}
.find_select {
  display: flex;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #707070;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline: 2%;

  select {
    font-size: 14px;
    outline: none;
    cursor: pointer;
  }
}
.find_search {
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 10px;
  border: 1px solid;
  width: 50%;
}
.find_button {
  position: relative;

  margin-top: -12px;
  width: 128px;
  height: 44px;
  border-radius: 10px;
  text-align: center;

  letter-spacing: 0px;
  color: #ffffff;
  background: #6083f7;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 27px;
  opacity: 1;
  border: none;
  cursor: pointer;
}

.find_thirddiv {
  text-align: center;
  padding: 10px 0px 0px 0px; font-size: 14px;;
}
.topics {
  margin-inline: 5%;
}
.topic_secdiv {
  margin-top: 20px;

  margin-bottom: 30px;
  margin-inline: 30px;

  height: auto;
}
.topic_imgage_container {
  width: 100%;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.topic_img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.topic_grid_item {
  text-align: center;

  padding: 3%;
  height: auto;
}
.topic_buttondiv {
  display: flex;
  justify-content: center;
}
.topic_word_container {
  background-color: #f5f9fd;
  font-size: 16px;
  opacity: 1;
  border-radius: 0 0 20px 20px;
  height: auto;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 10px 4% 30px 4%;
}
.topic_heading {
  font-size: 18px;
  margin-bottom: 10px;;
}
.exp {
  display: flex;

  padding: 50px;
  height: 500px;
  align-items: center;
  margin: 50px;
}
.exp_leftdiv {
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  margin-left: 10%;
}
.exp_rightdiv {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;
  margin-right: 1%;
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  align-items: center;
  padding: 5%;
}
.exp_rightdiv::-webkit-scrollbar {
  width: 0;
}

.exp_rightdiv::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.card {
  width: 500px;

  height: auto;

  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  align-items: start;
  padding: 2%;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #00000029;
  border-radius: 10px;
  opacity: 1;
  transition: transform 0.3s, background-color 0.3s;
}
.profile-info {
  display: flex;
  justify-content: flex-start;
  padding: 1%;

  align-items: center;
}
.additional-info {
  color: #5c5c5c;
  font-size: 18px;
}
.profile-info img {
  border-radius: 100%;
  width: 20%;
  height: 20%;
  margin-right: 5%;
}

.card:hover {
  transform: translateX(-50px);
  background-color: #6083f7;
  color: white;
  .additional-info {
    color: white;
  }
}

.card.hovered {
  transform: translateX(-50px);
  background-color: #6083f7;
  color: white;
}

.find-agents-box-wrap {
  display: flex;
  width: 96%;
  margin: 0 auto;
}
.find-agents-box-1 {
  width: 200px;
  margin: auto;
}
.find-agents-box-2 {
  flex: 1;
  padding: 0px 10px;
  margin: auto;
}
.find-agents-box-3 {
  /* width: 150px;*/
}


.button-a-1 {
  padding: 13px 24px;
  border-radius: 10px;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  background: #6083f7;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 270px;
  opacity: 1;
  border: none;
  cursor: pointer;
}

.location-icon{ background-image: url(./img/location-2.svg); background-repeat: no-repeat;}
.search-icon{ background-image: url(./img/search-2.svg); background-repeat: no-repeat;}


/*Styles-Strat*/

.home-sec-1-wrap{font-family: "Poppins", sans-serif;;  width: 100%; overflow: hidden;}
.home-sec-1-1{ width: 300px;   float: left;box-sizing: border-box;}
.home-sec-1-2{ width: calc(100% - 320px); float: right; padding: 0px 0px 0px 2% ; margin: auto ;box-sizing: border-box;;}

.home-sec-1-wrap h3{ text-align: left;font-size: 29px; color: #000; margin: 0px; padding: 0px;;padding-left: 30px;}
.home-sec-1-wrap h3 span{ display: block; padding: 6px 10px; color: #fff; background-color: #6083F7; 
  margin-left:-60px; padding-left:60px;
}
.home-sec-1-1 button{ background-color: transparent; padding: 0px; margin: 0px; width: 100%; 
  text-align: center; font-size: 22px; text-decoration: underline; font-style: italic; border: 0px;
  display: inline-block;
  width: fit-content;
  margin-left: 30px;  font-weight: 500; font-family: "Poppins", sans-serif;;  margin-top: 16px;
  ;}

.home_headline h2{text-align: left;font-size: 26px; color: #000;}






  @media screen and (max-width:959px){
    .home-sec-1-1{ width: 100%; float: none;}
    .home-sec-1-2{     width: calc(100% - 0px);}

    nav.nav-elements{display: none !important;;}
    nav.active{display: block !important;;}
    }
    @media screen and (max-width:767px){
      .find-agents-box-wrap{ flex-wrap: wrap;}
.find-agents-box-1{ width: 100%; padding: 0px 0px 10px 0px;;}
.find-agents-box-2{ padding: 0px;; padding-right: 10px;;}
.home-sec-1-wrap h3 {
  font-size: 20px;
  line-height: 32px;
}
.home_headline{margin-left: 0%; text-align: center; padding: 5px 0px; }
.home_headline h2{ text-align: center; line-height: 28px; font-size: 20px;;}

.find_border {
       
  margin: 10px 0px 10px 0px;
  width: 100%;
  padding: 14px 10px;
}
    }


