/* Header.css */
.Agent_Login {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.login_heading {
  margin-bottom: 5%;
  text-align: center;
  padding: 0px;

  font-size: 2em;
}
.login_form {
  background-color: white;
  box-shadow: 0px 0px 25px #00000026;
  border-radius: 20px;
  opacity: 1;
  margin-top: 5%;
  display: flex;

  flex-direction: column;
  align-items: center;
  align-content: center;
  margin-inline: 30%;
  padding: 4%;
  margin-bottom: 4%;
  border-radius: 2em;
  border: none !important;
}

.input-field {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;
}

.input-field::placeholder {
  margin-left: 8%;
}

.forgot-signup {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.forgot-password {
  text-align: left;

  margin-left: -2%;
  color: #6083f7;
}

.signup {
  display: flex;
  align-items: center;
  text-decoration: none;

  transition: color 0.3s ease-in-out;
}
.signup-link {
  margin-left: 1vw;
  color: #6083f7;
}

@media (max-width: 768px) {
  .login_form {
    margin-inline: 20%;
  }
}
/*  */
