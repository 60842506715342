/* Header.css */
.Agent_signup{
    display: flex;
    height: 100%;
    
    flex-direction: column;
    width: 100%;
    .signup-link{
      margin-left: 1vw;
      
      color: red;
  }
    
    }
    .signup_heading{
        margin-bottom: 5%;
    text-align: center;
        padding:0px;
        
        
        font-size: 1em
    }

 

    .file_upload{
      
       width:100%;
       display:flex;
       
      

    }
    .error-message{
      color:#FFD9D8;
      
      

    }
    .checkbox-group {
      text-align: left;
      display: flex;
      
      margin-left: -6%;
      flex-direction:row;
      width: 100%;
      justify-content: flex-start;
      
      input{
      
        border: 2px solid blue;
      }
    }
  
    .input-field {
     
      padding: 8px;
      margin: 8px 0;
      border: 1px solid #CCCCCC;
      border-radius: 4px;
      transition: border-color 0.3s ease-in-out;
      
      font-size: 12px;
      color: black;

      
    }
    
    .input-field::placeholder {
      margin-left: 8%;
    }
    
    .forgot-signup {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    
    .forgot-password {
      text-align: left;
    }
    
    .signup {
     
    display: flex;
    align-items: center;
      text-decoration: none;
      
      transition: color 0.3s ease-in-out;
    }
  
  
    @media (max-width: 768px) {
      
    }
    /*  */
    