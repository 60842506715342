/* Card.css */

.userdash {
  height: 100%;
}
.userdash_heading {
  margin-left: 3%;
  margin-bottom: 4%;
  font-size: 30px;

  margin-top: 2%;
}
.userdash_cards {
  display: flex;

  /* justify-content: space-between; */
  /* margin-left: 5%; */
  /* margin-right: 10%; */

  width: 100%;
  height: auto;
  justify-content: space-between;
  flex-wrap: wrap;
}

.usertable_div {
  padding: 20px 15px !important;
}
.usertable {
 
  border: 1px solid #ccc !important;
  width: 100%;
  box-shadow: 0px 0px 25px #00000026;
}
.userdash_card {
  box-shadow: 0px 0px 25px #00000026;
  background-color: white;
  padding: 18px 24px;

  width: 30%;
  height: 180px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.usertable {
  width: 100%;
  /* border-collapse: collapse; */
  box-shadow: 0px 0px 25px #00000026;
  border-radius: 20px;

  font-size: 16px;
}
.th {
  border-right: none;
  padding: 1.5%;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
}

.usertable tr:last-child td{ border: 0px;;}
.userdash_card-title {
  text-align: start;
  font-size: 17px;
  margin-bottom: 5px;
}

.userdash_card-word1 {
  display: block;
  text-align: start;
  font-size: 14px;
  margin-bottom: 20px;
  color:#999999;
}
.userdash_card-word2 {
  display: block;
  text-align: start;
  font-size: 38px;
}
.userdash_icon {
  height: fit-content;

  width: fit-content;
}

.status-capsule {
  display: inline-block;

  text-align: center;
}

.status-capsule.Completed {
 
  background-color: #ccc;
  color: white;
  padding: 1px;
  padding: 2px 10px;
  font-size: 10px;
  text-align: center;
  border-radius: 15px;
  display: inline-block;
  margin-left: 10px;
}

.status-capsule.Upcoming {
  background-color: #009f49;
  color: white;
  padding: 1px;
  padding: 2px 10px;
  font-size: 10px;
  text-align: center;
  border-radius: 15px;
  display: inline-block;
  margin-left: 10px;
}


@media screen and (max-width:830px) {
  .userdash_card {
    box-shadow: 0 4px 30px rgba(0, 1, 1, 0.1);
    background-color: white;
    padding: 10px 20px;
  
    width: 100%;
    height: 180px;
    margin-bottom: 10px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
