.fb_container {
    height: auto;


 th{
  cursor: pointer;
 }
 padding:1%;
 margin-inline: 2%;
  }
  .fb_cards-list{
    width :100%;
   
    

  }
  .fb_cards-list1{
  
  
  }
  .fb_sort{

 
   thead{
  margin-top: 0.5%;

    position:absolute;
  background-color: #6083F7;
  color: white;
  
    width:12%;
    z-index: 10001;
    border-radius: 10px;
    padding: 1%;
    right: 5%;

   }
    
   
  /* bottom:calc(4%+10)px; */
    
  }
  .fb_card-content{
    border: none !important;
  width:140vh;
  padding: 1%;


  }
  .fb_sort-section {
    display: flex;
 /* padding-right: 10%; */
 margin-right: 2%;  
justify-content: space-between;
  flex-direction: row;
    align-items: center;

    
  }
  
  .fb_sort-label {
    margin-right: 8px;
    font-weight: 600;
  }
  
  .fb_sort-select {
    padding: 8px;
  }
  
  .fb_card-section {
    display: flex;
    flex-direction: row; 
    box-shadow: 0px 0px 25px #00000026;
    width:100%;
    border-radius: 20px;
    background-color: white;
    padding: 2%;
 

  }
  .fb_cardhead{
    display: flex;
    justify-content: center;
  }
  
  .fb_card {
    background-color: #ffffff;

    transition: border-color 0.3s;
     padding:2%; 
     background: #FFFFFF 0% 0% no-repeat padding-box;
     border: 1px solid #CCCCCC;
     border-radius: 10px;
     opacity: 1;
  
    

  }
  
  .fb_card:hover {
    border:1px solid #6083F7;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  }
  
  .fb_card-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: 1%;
  }
  
  .fb_avatar {
    position: relative;
  }
  
  .fb_avatar-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .fb_avatar-fallback {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .fb_card-title {
 
 font-size: 18px;
  }
  
  .fb_card-date {
    margin: 4px 0;
    color: #666666;
    
    font-size: 14px;
  }
  
  .fb_rating-section {
    display: flex;
    gap: 4px;
  }
  
  .fb_star-icon {
    font-size: 3em;
    color: #cccccc;
  }
  
  .fb_star-icon.yellow {
    color: #f0c419; /* Yellow color */
  }
  
  
  
  .fb_rating-progress {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
  }
  .fb_card-content{
    width: 100%;
  }
  .fb_card-description {
    margin-top: 8px;
    white-space: normal;
    width:inherit;
  
  }
  
  .fb_pagination {
    display: flex;
    justify-content:space-between;
    margin-top: 16px;
width: 100%;

 
   
  }
  
  /* .fb_page-btn {
    padding: 8px 16px;
    margin-right: 4px;
    border: 1px solid #007bff;
    background-color: transparent;
    color: #007bff;
    cursor: pointer;
  } */
  
  .fb_page-btn:last-child {
    margin-right: 0;
  }
  .cards-list {
    width: 100%;
    border-collapse: collapse;
  }
  
  
  
  
  .pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-container button {
    margin: 0 5px;
  }
/* Pagination Button Styles */


.fb_page-btn {
  background-color: #ffffff;
  border: 1px solid #CCCCCC;
  color: #333;
  padding: 7px 8px;

font-size: 14px;
  cursor: pointer;
}

.fb_page-btn:hover {
  background-color: #f0f0f0;
}

.fb_page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Active Page Button Styles */
.fb_page-btn.active {
  background-color: #007bff;
  color: #ffffff;
}

/* Min/Max Page Button Styles */
.fb_page-btn.min-max {
  font-weight: bold;
}

/* Prev/Next Button Styles */
.fb_page-btn.prev-next {

  
font-size: 14px;
}
  