
.icon-desktop:before, .icon-menu-close:before, .icon-menu:before, .icon-mobile:before, .icon-submenu-down:before, .icon-submenu-right:before, .icon-world:before, .nav-button:before, .nav-no-js .nav-close:before, header h1:before, nav .submenu>a:after, nav>ul>.submenu>a:after {
	font-family: icon-font;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
	vertical-align: top
}
.icon-desktop:before {
	content: "\E001"
}
.icon-menu-close:before {
	content: "\E002"
}
.icon-menu:before {
	content: "\E003"
}
.icon-mobile:before {
	content: "\E004"
}
.icon-submenu-down:before {
	content: "\E005"
}
.icon-submenu-right:before {
	content: "\E006"
}
.icon-world:before {
	content: "\E007"
}
.nav-close {
	display: none
}
nav {
	
	top: 0;
	left: 0;
	right: 0;
	z-index: 900;
	overflow-x: hidden;
	overflow-y: auto;
}
nav ul {
	
	position: relative;
	zoom: 1;
	list-style-type: none;
	margin: 0;
	padding: 0
}
nav a, nav a:active, nav a:hover, nav a:visited {
	display: block;
	position: relative;
	color:#000

}
.nav-no-js nav li:hover>ul {
	display: block;
    background: #3E8DE8;
}
/*body,nav{padding-top:0px}
header{display:block;position:fixed;top:0;left:0;right:0;z-index:901;padding-left:10px;line-height:4.4rem;background:#26201C;color:#A69A94}
header h1{margin:0;font-size:1.7rem;line-height:4.4rem}
header h1:before{content:"\E004";margin-right:8px}
*/

.nav-button {
	display: block;
	position: absolute;
	top: 15px;
	right: 0;
	width: 4.4rem;
	height: 4.4rem;
	z-index: 902;
	cursor: pointer;
	text-align: center;
	line-height: 4.4rem;
	background: #5C5C5C;
	color: #A69A94
}
.nav-button:before {
	content: "\E003"
}
nav {
	line-height: 30px;
	background: none;
	
	
	font-size: 16px;
}
nav ul {
	border-radius: 0 0 6px 6px;
	
}


nav ul li ul {
	background: #fff;
	display:none;
	min-width: 230px;;
	max-width: 230px;
}


nav ul ul ul {
	background: #fff;
	min-width: 320px;;
	max-width: 320px;
}
nav ul ul ul ul {
	background: #40362f
}
nav ul ul ul ul ul {
	background: #493e36
}
nav li {
	border-top: 1px solid #59544F;
	cursor: pointer;
	text-align: left;
	padding: 0px 10px;
	
}
nav li:first-child{ border-top:0px;}
nav .submenu li {
	padding-left: 10px;
	    
}
nav li:hover>a {/*color:#5baaf0;background:#2E2722*/
	 
	/*border-bottom:2px solid #93BD69;*/
    color: #BF991F !important;
	
}
nav li a.active {
	color: #6083F7;
	/*border-bottom: 3px solid #3F9A3C*/
	/* background-image: url(images/hover.png); */
	background-repeat: no-repeat;
	background-position: left top 4px;
    background-size: 32px;
}
nav .submenu>a {
	padding-right: 30px!important
}
nav a, nav a:active, nav a:hover, nav a:visited {
	
	color: #000;
	text-decoration: none;
	font-size: 16px;
	padding: 0px;
    margin: 0px 0px;
}

.smaller nav a,.smaller nav a:active {color: #000 !important}

.smaller nav a:hover,.smaller nav a:visited{color: #000 !important}


nav ul li ul li a{
	color: #fff !important;
	font-size:14px !important;
	padding:2px 8px 2px 8px !important;
	white-space: break-spaces;
    line-height: 18px;
	
	display:block;
	margin-bottom: 6px;;
}
nav .submenu>a:after {
	position: absolute;
	display: block;
	right: 10px;
	top: 1px;
	content: "\E005"
}
.nav-button22 {
	font-family: icon-font;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
	vertical-align: top;
	display: none;
	background-image: url(./img/menu.png);
	background-repeat: no-repeat;
	background-position: center center;
	top: 0;
	right: 0;
	width: 50px;
	height: 50px;
	z-index: 902;
	cursor: pointer;
	text-align: center;
	line-height: 50px;
	border-radius: 5px;
	background-color: #26201C;
	color: #fff;
}

/* 
nav a, nav a:active, nav a:hover, nav a:visited {
	padding: 10px 9px 10px 20px;
	color:#000
}
nav ul li ul li a, 
nav ul li ul li a:active{color: #000 } */
@media screen and (max-width:1280px){
	nav {
		
		font-size: 14px;
	}

nav a, nav a:active, nav a:hover, nav a:visited {
	padding: 4px 0px 3px;
	
}
nav a, nav a:active, nav a:hover, nav a:visited {
    font-size: 14px;
}

}

@media screen and (max-width:1170px){
nav a, nav a:active, nav a:hover, nav a:visited {
	padding: 4px 5px 3px;
}
nav a, nav a:active, nav a:hover, nav a:visited {
    font-size: 13px;
}
nav {
		
	font-size: 13px;
}

}




 @media only screen and (min-width:960px) {
nav {
	overflow: visible;
	width:100%;
}
nav li {
	position: relative;
	white-space: nowrap;
	margin: 0px 0px 0px 10px;
}
nav ul li ul li {
    border: 0px;
	position: relative;
	white-space: nowrap;
	margin: 0px 0px 0px 0%;
}
nav>ul>li {
	display: inline-block;
       
}
nav ul {
	display: block
}
nav ul ul {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 901;
     min-width: 150px;
	
}
     
nav ul ul ul {
	top: 5px;
	left: 95%;
	z-index: 902
}
nav ul ul ul ul {
	z-index: 903
}
nav ul ul ul ul ul {
	z-index: 904
}
nav ul ul li.nav-left>ul {
	left: auto;
	right: 95%
}
body {
	padding: 0!important
}
header {
	position: relative
}
header h1:before {
	content: "\E001"
}
.nav-button {
	display: none
}
nav {
	display: inline-block;
	position: relative
}
nav>ul>li {
	border-top: none;
}
nav li>ul {
	box-shadow: 0 0 5px 0 rgba(0,0,0,.75);
    background: #3E8DE8;
	
}
nav ul ul ul {
	border-radius: 0 6px 6px
}
nav ul ul ul li:first-child {
	border-top: none
}
nav .nav-left>ul {
	border-radius: 6px 0 6px 6px;
    
}
nav .submenu li {
	padding: 0;
	min-width: 180px
}
nav>ul>.submenu>a:after {
	content: "\E005"
}
nav .submenu>a:after {
	content: "\E006"
}
}
@media only screen and (max-width:959px) {
.nav-no-js .nav-close {
	position: fixed;
	top: 0;
	right: 4.4rem;
	z-index: 902;
	font-size: 1.7rem;
	line-height: 4.4rem;
	text-align: center;
	background: #26201C;
	
}
.nav-no-js .nav-close:before {
	content: "\E002"
}
.nav-no-js .nav-button:hover~nav, .nav-no-js nav:hover {
	bottom: 0
}
.nav-no-js .nav-button:hover~nav+.nav-close, .nav-no-js .nav-button:hover~nav>ul, .nav-no-js nav:hover+.nav-close, .nav-no-js nav:hover>ul {
	display: block
}
.nav-button22 {
	font-family: icon-font;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
	vertical-align: top;
	display: block;
	/* background-image: url(images/menu.png); */
	background-repeat: no-repeat;
	background-position: center center;
	top: 0;
	
	right: 0;
    width: 40px;
    height: 36px;
    z-index: 902;
    cursor: pointer;
    text-align: center;
    line-height: 50px;
    border-radius: 5px;
    background-color: #6083F7;
    color: #fff;
    float: right;
    margin-top: -48px;
	
}
nav ul li ul {
  
    min-width: 100%;
    max-width: 100%;
}
}