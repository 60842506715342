.consultations-container {
  margin: 0 2%;
  padding: 2rem;
  select {
    outline: none;
    border-radius: 20px;
  }
  select option {
    border: none;
    outline: none;
  }

  input {
    border-radius: 20px;
  }
}

.consultations_heading {
  margin-top: 2%;
  margin-bottom: 5%;
  font-size: 30px;
}
.table-container {
  overflow: hidden;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin-bottom: 5%;
}

.consultations-table {
  width: 100%;
  border-collapse: collapse;
  height: 100%;
  background-color: #fff;
}

.consultations-table td,
.consultations-table th {
  text-align: left;
}

/* Status Button styles */

.status-button {
  width: 100%;
  padding: 0px;
  margin: 0px;
  border-radius: 2rem;
  cursor: pointer;
  font-weight: 600;
  cursor: auto;
}

.status-button.active {
  background-color: #009f49;
  color: #ffffff;
  font-size: 14px;

  width: 100px;
  height: 28px;
  border: none;
  text-align: center;
}

.status-button.inactive {
  background-color: #cccccc;
  color: white;
  font-size: 14px;

  width: 100px;
  height: 28px;
  padding: 1%;
  border: none;
}

/* Action Button styles */

.action-button {
  background-color: white;
  border: none;
}

.action-button.edit {
}

.action-button.delete {
}
.action-button.save {
  background-color: #cccccc;
  color: black;
  font-size: 14px;

  width: 100px;
  height: 28px;
  padding: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 2rem;
}

.add-consultation-container {
  display: flex;
  justify-content: space-between;

  margin-bottom: 2%;
  align-items: stretch;
}

.add-consultation-container input {
  width: 30%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.edit-input {
  border: none;
  border-bottom: 1px solid #ccc;
  width: 80%;
  padding: 0.5rem;
  margin-bottom: 0.25rem;
}
.popup {
  position: fixed;
  top: 0%;
  left: 0%;
  /* transform: translate(-50%, -50%); */
  background-color: white;
  padding: 0px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  width: 100%;
}
.close-btn-1 {
  position: fixed;
  z-index: 999;
  top: 50px;
  right: 50px;
  background-color: #fff;
  width: 50px;
  height: 50px;
  padding: 10px;
}
.close-btn-1 img {
  width: 100%;
  height: auto;
  margin: 0px !important;
}
.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the opacity for the desired blur effect */
  z-index: 999;
  backdrop-filter: blur(5px); /* This creates the blur effect */
}
.popup_button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-inline: 30%;
  margin-top: 5%;
}
button {
  cursor: pointer;
}
