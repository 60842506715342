.pi_container {
    background-color: #ffffff;
  
    min-height: 100vh;
    padding: 3%;
    margin-right: 0%;
   
    margin-bottom: 1%;
   
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #00000026;
    border-radius: 20px;
  
  }
  .pi_container  *{
    font-size: 14px;
    
    font-weight: bold;
  
  }
  .personalinfo {
  margin: 0px;
  }
  
  .personalinfo  {
  textarea{
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    
  }
  
  }
  
  .pi_heading {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  
  
    .pi_profile-section {
      position: relative;
      display: inline-block; /* Ensure the container takes up only the space needed */
    }
    
  
  
  .pi_profile-img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
  
  }
  
  .pi_profile-details {
    margin-left: 1rem;
  }
  
  .pi_profile-heading {
    font-size: 1.125rem;
    font-weight: 500;
  }
  
  .pi_edit-section {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1; 
  }
  
  .pi_edit-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
  }
  
  .pi_edit-text {
    font-size: 0.875rem;
  }
  
  .pi_form-section {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    align-items: center;
  }
  
  .pi_form-section select {
    flex: 1;
    
  }
  
  .pi_form-section input[type="text"] {
    flex: 1;
    
  }
  .pi_form-section_cert {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  
  .personalinfo select {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
  font-size: 14px;
  
  font-weight:normal;
  border: 1px solid #CCCCCC;
  border-radius: 5px;;
    width: 90%;
    min-height: 50px;
  
  
    
  }
  .personalinfo input{
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
  font-size: 14px;
  
  font-weight:normal;
  border: 1px solid #CCCCCC;
  border-radius: 5px;;
    width: 90%;
    min-height: 40px;
  }
  
  .pi_intro-textarea {
    padding: 0.5rem;
    height: 140px;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
  
    width: 195%;
  }
  
  .pi_save-btn {
    width: 10%;
    padding: 0.5rem;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-top: 1%;
  }
  
  .pi_upload-btn {
   
  
  width: 100%;
    color: black;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    border: 1px solid #007bff;
    text-align: center;
  padding-block: 2%;
  font-size: 14px;
  
    
   
  }
  
  
  
  /* .pi_upload-btn {
    background-color: transparent;
    color: #007bff;
   
  } */
  
  .pi_upload-btn:hover {
    background-color: #f0f0f0;
  }
  
  .custom-line {
    margin-inline: -0.1%;
  }
