
  
  .Agents_remarks_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
   
  }
  
  .header-item {
    font-size: 1.1rem;
    font-weight: 600;
  }
  
  .data-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .avatar {
    /* Styles for the avatar component */
  }
  
  .font-medium {
    font-weight: 500;
  }
  .booking_date1{
    /* min-width: 250px; */
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    align-items: center;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    opacity: 1;
    height: 60px;
    font-size: 14px;
    
   padding-inline: 2%;
  
  }
  .select-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    height: 50px;
    margin-inline: 5%;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .select-container1 {
 display: flex ;
 flex-direction: row;
justify-content: space-between;
  

 
    margin-top: 3%;
    margin-bottom: 5%;
    margin-inline: 4%;
  }
  

  
  .select-trigger {
    width: 100%;
    height: 100%;
   
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
  }
  
  .submit-btn {
    display: flex;
    justify-content:flex-start;
    margin-top: 1rem;
    margin-inline: 4%;
  }
  
  .btn {
    background-color: #3498db;
    color: #ffffff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  /* Add more styles as needed */
  