/* Header.css */
.user_Login{
    display: flex;
    height: 100%;
    
    flex-direction: column;
    
    padding-bottom: 4%;
    }
    .login_heading{
       margin: 0px; padding: 0px 0px 20px 0px; 
    text-align: center;
      
        
        font-size: 2em
    }

    .input-field{}
    
    /* .userlogin_form {
        background-color:white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
      display: flex;
      flex-direction: column;
      align-items:center;
      align-content: center;
      border-radius: 2em;
      border:1px solid #011D70;
      
    
    
    } */

    .userlogin_form { width:96%;max-width: 600px; 
      
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 25px #00000026;
      
      display: flex;  flex-direction: column;  align-items: center; 
      align-content: center; border-radius: 20px; border: 1px solid #011d70; 
       border: none; padding: 30px;;  margin: 50px auto; }


    
    .input-field {
      width: 100%;
      padding: 8px;
      margin: 8px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      transition: border-color 0.3s ease-in-out;
    }
    
    .input-field::placeholder {
      margin-left: 8%;
    }
    
    .forgot-signup {
      display: flex;

      
      
      width: 100%;
  cursor: pointer;
  color: red;
  
    }
    
    .forgot-password {
      text-align: left;
      
    }
    
    .signup {
     
    display: flex;
    align-items: center;
      text-decoration: none;
      
      transition: color 0.3s ease-in-out;
    }
    .signup-link{
        margin-left: 1vw;
    }
 
    @media (max-width: 768px) {
        .login_form{
           margin-inline: 20%; 
        }
    }
    /*  */
    