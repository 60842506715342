.user_pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
}
.user_page-btn:last-child {
  margin-right: 0;
}
.cards-list {
  width: 100%;
  border-collapse: collapse;
}

th {
  border-right: none;
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
}

/* Pagination Button Styles */

.user_page-btn {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  color: #333;
  padding: 7px 15px;

  font-size: 14px;
  cursor: pointer;
}

.user_page-btn:hover {
  background-color: #f0f0f0;
}

.user_page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Active Page Button Styles */
.user_page-btn.active {
  background-color: #011d70;
  color: #ffffff;
}

/* Min/Max Page Button Styles */
.user_page-btn.min-max {
}

/* Prev/Next Button Styles */
.user_page-btn.prev-next {
}

.link-4 {
  padding: 6px 5px;
  margin: 0px;
  background-color: #fff;
  color: #fff;
  font-size: 10px;
  border: 1px solid #ccc;
  border-radius: 100px;
  background-color: #009f49;
  text-align: center;
  display: inline-block;
}
.link-5 {
  padding: 6px 5px;
  margin: 0px;
  background-color: #ccc;
  color: #000;
  font-size: 10px;
  border: 1px solid #ccc;
  border-radius: 100px;
  text-align: center;
  display: inline-block;
}
.text-center {
  text-align: center !important;
}

.usertable tr td:last-child {
  text-align: center !important;
}
.usertable tr th:last-child {
  text-align: center !important;
}
@media screen and (max-width: 830px) {
  .user_pagination {
    flex-wrap: wrap;
  }
  .user_paginatio div {
    flex: inherit;
    width: 100%;
    padding: 5px 0px;
  }
}
