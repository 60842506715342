.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  background-color: #f5f9fd;
}
.rmdp-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 5px;
  opacity: 1;
  min-height: 50px;
  min-width: 250px;
  margin: 1px 0;
  padding: 30px;
}
.component-container {
  padding: 2%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 25px #00000026;
  opacity: 1;
}
.Agents_Avaliabillity {
  padding: 1%;
  padding-inline: 3%;
}
.component-heading {
  font-size: 30px;

  margin-bottom: 4%;
}

.component-subheading {
  font-size: 18px;
  margin-bottom: 10px;
}

.component-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.component-button {
  font-size: 14px;
  padding: 10px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  width: 120px;
  color: #666666;
  box-shadow: 0px 0px 12px #00000029;
}
.component-button1 {
  font-size: 16px;
  padding: 10px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.MonthSection {
  background-color: #f5f9fd;
  background: #f5f9fd 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-bottom: 2%;
  padding: 3%;
}
.WeeksSection {
  background-color: #f5f9fd;
  background: #f5f9fd 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-bottom: 2%;
  padding: 3%;
}
.TimeSection {
  background-color: #f5f9fd;
  background: #f5f9fd 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 3%;
}

.selected1 {
  box-shadow: 0px 0px 12px #00000029;
  border: 1px solid #6083f7;
  border-radius: 5px;
  opacity: 1;
}

.component-button.selected.editing-weeks2 {
  color: white;
  border-color: orange;
}
.component-edit-button {
  background: #f0efed 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 5px;
  opacity: 1;
  padding: 5%;

  color: #666666;

  font-size: 14px;
  width: max-content;
}
.component-button-container1 {
  display: flex;
  flex-direction: row;

  width: 180px;
  min-height: 30px;
}
.component-save-button {
  font-size: 14px;
  padding: "5%";
  border: 1px solid #6083f7;
  box-shadow: 0px 0px 25px #00000026;
  border-radius: 5px;
  cursor: pointer;
  width: max-content;
  background-color: #6083f7;
  color: white;
  margin-left: 2%;
}
.component-cancel-button {
  font-size: 14px;
  padding: "5%";
  border: 1px solid red;
  box-shadow: 0px 0px 25px #00000026;
  border-radius: 5px;
  cursor: pointer;
  width: max-content;
  background-color: white;
  color: black;
}
