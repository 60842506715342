.User_Reschedule {
  margin-top: 2rem;

  padding: 1.5rem; /* Equivalent to p-6 */

  /* width: 100%; */
  width: auto;
}
.Remarks_container {
  background-color: white;

  box-shadow: 0px 0px 25px #00000026;
  border-radius: 20px;
  opacity: 1;
  margin: 0px;

  /* border:1px solid black; */
}

.User_Reschedule_header {
  display: flex;
  justify-content: space-between; /* Equivalent to justify-between */
  align-items: center; /* Equivalent to items-center */
  margin-bottom: 1.5rem; /* Equivalent to mb-6 */
}

.User_Reschedule_title {
  font-size: 1.875rem; /* Equivalent to text-3xl */
  font-weight: 600; /* Equivalent to font-semibold */
}

.User_Reschedule_cancel-btn {
  background-color: white; /* Equivalent to bg-gray-100 */
  color: black; /* Equivalent to text-gray-700 */
  border: 1px solid black;
  border-radius: 30px;
  padding: 1%;
  width: 8%;
}

.User_Reschedule_table {
  width: 100%; /* Equivalent to w-full */
  border-collapse: collapse;
  border: 1px solid #e5e7eb; /* Equivalent to border */
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  border-right: none;
  border-top: none;
  border-left: none;
}

.User_Reschedule.th,
td {
  padding: 1rem;
  text-align: left;
  border-right: none;
}
.User_Reschedule {
  td {
    padding-left: 5%;
  }
  th {
    padding-left: 5%;
    padding-bottom: 15px;
    padding-top: 15px;
    width: max-content;
    font-size: 16px;
    font-weight: 500;
  }
}

.User_Reschedule_avatar {
  width: 2rem; /* Equivalent to w-8 */
  height: 2rem; /* Equivalent to h-8 */
  /* background-color: #d1d5db; Equivalent to bg-gray-300 */
  border-radius: 9999px; /* Equivalent to rounded-full */
  display: flex;
  align-items: center; /* Equivalent to items-center */
  justify-content: center; /* Equivalent to justify-center */
}

.User_Reschedule_submit-btn {
  display: flex;
  justify-content: start; /* Equivalent to justify-center */
  /* Equivalent to mt-4 */
  margin-top: 2%;
}

/* .container {
    max-width: 40rem;
    margin: 0 auto;
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  } */

.Agents_remarks_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.data-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.select-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  height: 50px;
  margin-inline: 5%;
  margin-top: 3%;
  margin-bottom: 5%;
}

.select {
  position: relative;
  height: 100%;
}

.select-trigger {
  width: 100%;
  height: 100%;

  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.submit-button {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.btn {
  background-color: #3498db;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

/* Add more styles as needed */
.booking_date {
  min-width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 5px;
  opacity: 1;
  height: 60px;
  font-size: 14px;

  padding-inline: 2%;
  /* margin-right: 2%; */
}
.custom-select {
  margin-right: 2%;
  min-width: 250px;
}

.selected-option {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}
.options_div {
  background: #f0f7ff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 5px;
  opacity: 1;
  padding: 2%;
  z-index: 10002;
  position: absolute;
  width: 200px;
}
.options {
  max-height: 150px; /* Max height of the dropdown */
  overflow-y: scroll; /* Enable vertical scrolling */
}

.option {
  padding: 8px;
  cursor: pointer;
  width: max-content;
  margin-right: 20px;
  font-size: 14px;
}

.option:hover {
  background-color: #011d70;
  color: white;
}

/* Custom scrollbar */
.options::-webkit-scrollbar {
  width: 5px;
}

.options::-webkit-scrollbar-track {
  background-color: #011d70;
  border-radius: 5px;
}
.green {
  color: "green";
}

.options::-webkit-scrollbar-thumb {
  background-color: #6083f7;
  border-radius: 10px;
}


.border-box-main{ padding: 14px; border-radius: 20px; border: 1px solid #011D6E; box-sizing: border-box; width: 100%;}