/* Card.css */

.dash{
  height: 100%;
}
.dash_heading{
 margin-left: 6%;
  margin-bottom: 4%;
  font-size: 30px;
  
  margin-top: 1%;
}.dash_cards{
  display:flex;
  flex-direction: row;

  /* justify-content: space-between; */
  /* margin-left: 5%; */
  /* margin-right: 10%; */
  margin-bottom: 5%;
 
 margin-left: 6%;
height: auto;

}
.dash_view_all{
margin-inline-end: 3%;
}
.dash_card {
    border-radius: 10%;
    box-shadow: 0px 0px 25px #00000026;
    background-color: white;
    padding: 2%;

    margin-right:5%;
   
 width:20%;
    height: 160px;
    width:300px;

  }
  
  .dash_card-title {
    
    text-align: start;
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .dash_card-word1 {
    display: block;
    text-align: start;
    
    font-size: 14px;
    margin-bottom: 20%;
    color:grey;
  }
  .dash_card-word2 {
    display: block;
    text-align: start;
    font-size: 38px;  
    
  }
  .dash_icon{
    height: fit-content;
    
   width: fit-content;
  }
  
  