/* Header.css */



.Agent_signup {
  display: flex;
  height: 100%;

  flex-direction: column;

  padding-bottom: 4%;
}
.signup_heading {
  margin-bottom: 5%;
  text-align: center;
  padding: 0px;


  font-size: 1em;
}
.checkbox-group {
  text-align: left;
  display: flex;
  margin-left: -6%;
  flex-direction: row;

  width: 100%;
  justify-content: flex-start;
  input {
    border: 1px solid blue;
  }
}

.file_upload {
  width: 100%;
  display: flex;
}
.error-message {
  color: #ffd9d8;

}
.input-field {
  padding: 8px;

  border: 1px solid #cccccc;
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;

  font-size: 12px;
  color: black;
}

.input-field::placeholder {
  margin-left: 8%;
}

.forgot-signup {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.forgot-password {
  text-align: left;
}

.signup {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}
.signup-link {
  margin-left: 1vw;
  color: #6083f7 !important;
}

@media (max-width: 768px) {
 
}
/*  */
