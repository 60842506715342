/* Sidebar.css */
.sidebar{
  position: relative;
    margin-bottom: 0px;
}
.sidebar_left {
    /* width: 15%;
    height: auto;
    background-color: #F5F9FD;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    position: absolute;
      top: 0px;
      margin-top: 0px;
      left: 0;
      height: 100%;
      width: 260px;
      background: #fff;
      z-index: 100;
      transition: all 0.5s ease;
     /* background-image: url(../img/user_siderbar.png);*/
      background-repeat: no-repeat; background-position: bottom center; 
      background-color: #F5F9FD ;
      transition: transform 0.3s ease;
  }
  
  .sidebar-option {
    display: flex;
    text-decoration: none;
    color: #333;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    align-items: center;
    margin-inline: 10%;
    justify-content: flex-start;
    
    font-size: 14px;
  }
  
  .sidebar-option:hover {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar-option.active {
    background-color:white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-right: 3%;
    .sidebar_icon
    {
      background-color: #6083F7;
      padding:4%;
   
      border-radius: 4px;
display: flex;
align-items: center;
     
    }
  
  }
  .sidebar_icon{
    margin-right: 3%;
  }
.sidebar_right{
  position: relative;
  min-height: 100vh;
  left: 260px;
  width: calc(100% - 290px);
  transition: all 0.5s ease;
  margin-top: 0px;
  padding: 10px;
}

  /* App.css */
  /* .app-container {
    display: flex;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
  } */
  

  
@media screen and (max-width:830px){
 
 

  .sidebar_right {
      position: relative;
      background: #e4e9f7;
      min-height: 100vh;
      left: 0px;
      width: calc(100% - 0px);
      transition: all 0.5s ease;
      margin-top: 0px;
      width: 100%;
  }
  .pi_container{margin-right: 0% !important;}


}


.sidebar_left {
  transform: translateX(0%);
}

@media (max-width: 768px) {

  .sidebar-open .sidebar_left {
    transform: translateX(0);
  }
  
  .sidebar_left {
    transform: translateX(-100%);
  }

  

.sidebar_left { width: 250px; /* Make sidebar full width on smaller screens */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100%;}
}




/* usersidebar.css */
.usersidebar{
  position: relative;
   margin-bottom: 0px;
 
 }

 
 /* .usersidebar_left {
     width: 250px;;
     
     background-color: white;
     display: flex;
   
     flex-direction: column;
     justify-content: space-between;
 
     box-shadow: 0px 0px 25px #00000026;
     padding-top: 1%;

   }
    */

    .usersidebar_left{
     position: absolute;
     top: 0px;
     margin-top: 0px;
     left: 0;
     height: 100%;
     width: 260px;
     background: #fff;
     z-index: 100;
     transition: all 0.5s ease;
     /*background-image: url(../img/user_siderbar.png);*/
     background-repeat: no-repeat; background-position: bottom center; 
     background-color: #F5F9FD ;
     transition: transform 0.3s ease;
 }






   .usersidebar-option {
     display: flex;
     text-decoration: none;
     color: #333;
     padding: 10px;
     margin-bottom: 10px;
     border-radius: 5px;
     align-items: center;
     margin-inline: 10%;
     justify-content: flex-start;
     
     font-size: 14px;

 
   }
   
   .usersidebar-option:hover {
       background: #F5F9FD;
       box-shadow: 0px 0px 25px #00000029;
     
   }
   
   .usersidebar-option.active {
     background-color:white;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
     border: 1px solid #011D70;
border-radius: 5px;
     .usersidebar_icon
     {
       background-color: #011D70;
       padding: 5%;
       /* padding-inline: 5%; */
       border-radius: 4px;
       display: flex;
       align-items: center;
      
     }
   
   }
   .usersidebar_icon{
     margin-right: 3%;
   }
 .usersidebar_right{
     position: relative;
     min-height: 100vh;
     left: 260px;
     width: calc(100% - 290px);
     transition: all 0.5s ease;
     margin-top: 0px;
     padding: 24px 10px 10px 10px;
 }
 
 
   /* App.css */
   /* .app-container {
     display: flex;
   }
   
   .main-content {
     flex: 1;
     padding: 20px;
   } */
   


@media screen and (max-width:830px){



 .usersidebar_right {
     position: relative;
     background: #e4e9f7;
     min-height: 100vh;
     left: 0px;
     width: calc(100% - 0px);
     transition: all 0.5s ease;
     margin-top: 0px;
     width: 100%;
 }
 .pi_container{margin-right: 0% !important;}


}


.usersidebar_left {
 transform: translateX(0%);
}

@media (max-width: 768px) {

 .sidebar-open .usersidebar_left {
   transform: translateX(0);
 }
 
 .usersidebar_left {
   transform: translateX(-100%);
 }

 

.usersidebar_left { width: 250px; /* Make sidebar full width on smaller screens */
 position: absolute;
 top: 0;
 left: 0;
 z-index: 1000;
 height: 100%;}
}