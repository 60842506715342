.star-feedback {
    font-size: 50px;
    margin-left: 3%;
    
  }
  
  .star {
    color: #DEE2E3; /* Grey color for unselected stars */
    cursor: pointer;
  }
  
  .star_selected {
    color: #F7CC45; /* Yellow color for selected stars */
  }
  .User_feedback {

   
    margin-top: 2rem; 
 
    padding: 1.5rem; /* Equivalent to p-6 */
   
    width: 100%;

    
  }
  .feedback_container{
    margin-top: 2rem; 

 
    background-color: white; /* Equivalent to bg-white */
    border-radius: 20px; /* Equivalent to rounded-lg */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Equivalent to shadow */
   
    /* border:1px solid black; */
    width: 100%;
  }
  
  .User_feedback_header {
    display: flex;
    justify-content: space-between; /* Equivalent to justify-between */
    align-items: center; /* Equivalent to items-center */
    margin-bottom: 1.5rem; /* Equivalent to mb-6 */
  }
  
  .User_feedback_title {
    font-size: 1.875rem; /* Equivalent to text-3xl */
    font-weight: 600; /* Equivalent to font-semibold */
  }
  
  .User_feedback_cancel-btn {
    background-color:white; /* Equivalent to bg-gray-100 */
    color: black; /* Equivalent to text-gray-700 */
    border: 1px solid black;
    border-radius: 30px;
    padding:1%;
    width: 8%;
  }
  
  .User_feedback_table {
    width: 100%; /* Equivalent to w-full */
    border-collapse: collapse;
    border: 1px solid #e5e7eb; /* Equivalent to border */
    border-radius: 0.375rem; /* Equivalent to rounded-md */
    border-right: none;
    border-top: none;
    border-left: none;
    font-size: 16px;
  }
  

  
  .User_feedback.th,
  td {
   
    padding: 10px; 
    text-align: left;
     border-right: none;font-size: 13px;;

 
    
  }
  .User_feedback
  {
    td{
    padding-left: 5%;

 
    }
    th{
      
      
      padding-left: 5%;
      padding-bottom: 15px;
      padding-top: 15px;
      width: max-content;
      font-size: 16px;
      font-family: "Poppins", sans-serif !important;
    font-weight: 500;
 
    }
  }
 
  
  .User_feedback_user-info {
    display: flex;
    align-items: center; /* Equivalent to items-center */
    gap: 0.75rem; /* Equivalent to space-x-3 */
  }
  
  .User_feedback_avatar {
    width: 2rem; /* Equivalent to w-8 */
    height: 2rem; /* Equivalent to h-8 */
 /* Equivalent to bg-gray-300 */
    border-radius: 9999px; /* Equivalent to rounded-full */
    display: flex;
    align-items: center; /* Equivalent to items-center */
    justify-content: center; /* Equivalent to justify-center */
  }
  
  .User_feedback_feedback {
    margin: 1rem;
     /* Equivalent to mt-6 */

  }
  
  .User_feedback_feedback-input {
    width: 95%; /* Equivalent to w-full */
    padding: 1rem; /* Equivalent to p-4 */
    border: 1px solid #e5e7eb; /* Equivalent to border */
    border-radius: 0.375rem; /* Equivalent to rounded-md */
    margin:1%;
    min-height:20vh;
    margin-bottom: 0px;
  }
  
  .User_feedback_submit-btn {
    display: flex;
    justify-content: start; /* Equivalent to justify-center */
    /* Equivalent to mt-4 */
    margin-top: 2%;
  }
  
  .submit-btn-wrap{ padding: 10px 0px;;}
  .textarea-2{margin:20px 0px 10px 0px; }
 .star-feedback{}
 .textarea-2 textarea{ box-sizing:border-box;width:100%; height: 120px; border-radius: 10px; resize: none;
   border: 1px solid #ccc; background-color: #fff; padding: 14px;;}
 .star-feedback {
  font-size: 30px !important;
  margin-left: 0% !important; margin-bottom: 20px !important;
}
.star {
  color: #b2b2b2;
}

 
  