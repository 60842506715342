/* usersidebar.css */
.usersidebar {
  position: relative;
  margin-bottom: 0px;
}

/* .usersidebar_left {
      width: 250px;;
      
      background-color: white;
      display: flex;
    
      flex-direction: column;
      justify-content: space-between;
  
      box-shadow: 0px 0px 25px #00000026;
      padding-top: 1%;

    }
     */

.usersidebar_left {
  position: absolute;
  top: 0px;
  margin-top: 0px;
  left: 0;
  height: 100%;
  width: 260px;
  background: #fff;
  z-index: 100;
  transition: all 0.5s ease;
  background-image: url(../img/user_siderbar.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-color: #f5f9fd;
  transition: transform 0.3s ease;
}

.usersidebar-option {
  display: flex;
  text-decoration: none;
  color: #333;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  align-items: center;
  margin-inline: 10%;
  justify-content: flex-start;

  font-size: 14px;
}

.usersidebar-option:hover {
  background: #f5f9fd;
  box-shadow: 0px 0px 25px #00000029;
}

.usersidebar-option.active {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #011d70;
  border-radius: 5px;
  .usersidebar_icon {
    background-color: #011d70;
    padding: 5%;
    /* padding-inline: 5%; */
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
}
.usersidebar_icon {
  margin-right: 3%;
}
.usersidebar_right {
  position: relative;
  min-height: 100vh;
  left: 260px;
  width: calc(100% - 290px);
  transition: all 0.5s ease;
  margin-top: 0px;
  padding: 24px 10px 10px 10px;
}

/* App.css */
/* .app-container {
      display: flex;
    }
    
    .main-content {
      flex: 1;
      padding: 20px;
    } */

@media screen and (max-width: 830px) {
  .usersidebar_right {
    position: relative;
    background: #e4e9f7;
    min-height: 100vh;
    left: 0px;
    width: calc(100% - 0px);
    transition: all 0.5s ease;
    margin-top: 0px;
    width: 100%;
  }
  .pi_container {
    margin-right: 0% !important;
  }
}

.usersidebar_left {
  transform: translateX(0%);
}

@media (max-width: 830px) {
  .sidebar-open .usersidebar_left {
    transform: translateX(0);
  }

  .usersidebar_left {
    transform: translateX(-100%);
  }

  .usersidebar_left {
    width: 250px; /* Make sidebar full width on smaller screens */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
  }
}

.userdash {
  height: 100%;
}
.userdash_heading {
  margin-left: 3%;
  margin-bottom: 4%;
  font-size: 30px;

  margin-top: 2%;
}
.userdash_cards {
  display: flex;
  flex-direction: row;

  /* justify-content: space-between; */
  /* margin-left: 5%; */
  /* margin-right: 10%; */

  width: 100%;
  height: auto;
}

.usertable_div {
}
.usertable {
  border: 1px solid #011d6e;
  width: 100%;
}
.userdash_card {
  box-shadow: 0 4px 30px rgba(0, 1, 1, 0.1);
  background-color: white;
  padding: 10px 20px;

  width: 300px;
  height: 180px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.usertable {
  width: 100%;
  /* border-collapse: collapse; */
  box-shadow: 0px 0px 25px #00000026;
  border-radius: 20px;

  font-size: 16px;
}
.th {
  border-right: none;
  padding: 1.5%;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
}

.userdash_card-title {
  text-align: start;
  font-size: 18px;
  margin-bottom: 5px;
}

.userdash_card-word1 {
  display: block;
  text-align: start;
  font-size: 14px;
  margin-bottom: 25%;
  color: grey;
}
.userdash_card-word2 {
  display: block;
  text-align: start;
  font-size: 43px;
}
.userdash_icon {
  height: fit-content;

  width: fit-content;
}

.status-capsule {
  display: inline-block;

  text-align: center;
}

.status-capsule.Completed {
  background-color: #ccc;
  color: white;
  padding: 1px;
  padding: 2px 10px;
  font-size: 10px;
  text-align: center;
  border-radius: 15px;
  display: inline-block;
  margin-left: 10px;
}

.status-capsule.Upcoming {
  background-color: #009f49;
  color: white;
  padding: 1px;
  padding: 2px 10px;
  font-size: 10px;
  text-align: center;
  border-radius: 15px;
  display: inline-block;
  margin-left: 10px;
}
