/* Your existing styles */

.booking {
  margin-top: 2%;
  padding-top: 3%;
  padding-bottom: 3%;
  height: auto;
}

.booking_profile {
  display: flex;
  flex-direction: row;

  margin-inline: 8%;
}

.booking_pic {
  width: 20%;

  height: 100%;
  margin-right: 1%;
}

.booking_img1 {
  width: 100%;

  height: 100%;
  overflow: hidden;
  border-radius: 8%;
}

.booking_info {
  /* margin-top: 1%; */
  width: 100%;
}

.booking_name {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5%;
}

.booking_cost {
  font-family: popping_reg;
  margin-bottom: 0.5%;

  display: flex;
  height: 100%;
}
.booking_cost_icon {
  margin-top: 7%;
}

.booking_member {
  color: grey;
  margin-bottom: 0.5%;
  font-size: 14px;
}

.booking_domain {
  color: #6083f7;
  margin-bottom: 0.5%;
  font-size: 14px;
}

.booking_matter {
  font-size: 16px;
  margin-bottom: 1%;
}

.booking_date {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 5px;
  opacity: 1;
  height: 60px;
  font-size: 14px;

  
}
.custom-select {
  margin-right: 0%;
  width: 100%;
}

.appointment {
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 2%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.appointment_title {
  font-family: "Poppins", sans-serif !important;
    font-weight: 500;
  font-size: 1.2em;
}

.appointment_sec_div {
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
}

.appointment_date {
  margin-right: 10%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 5px;
  opacity: 1;
  min-width: 300px;
  padding-inline: 2%;
}
.custom_date {
  background-color: #f0f7ff;
  color: black;
}
.appointment_time {
  margin-right: 2vw;
}

select {
  border: none;
}
/* Media queries for responsiveness */

@media (max-width: 768px) {
  .booking_profile {
    flex-direction: column;
    align-items: center;
  }
  .appointment {
    height: auto;
  }
  .booking_pic {
    width: 70%; /* Adjust the width for smaller screens */
    margin-bottom: 1em; /* Add margin between the image and other content */
  }

  .booking_info {
    text-align: center; /* Center the text content on smaller screens */
  }
  .appointment_first_div {
    text-align: center;
  }
  .appointment_sec_div {
    flex-direction: column;
    align-items: center;
  }

  .appointment_date,
  .appointment_time,
  .appointment_button {
    margin-top: 1em;
    padding: 2%; /* Add spacing between elements */
  }
}

/* editign start ed */
/* CustomSelect.css */
/* CustomSelect.css */

.selected-option {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}
.options_div {
  background: #f0f7ff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 5px;
  opacity: 1;
  padding: 2%;
  z-index: 10002;
  position: absolute;
  width: 200px;
}
.options {
  max-height: 150px; /* Max height of the dropdown */
  overflow-y: scroll; /* Enable vertical scrolling */
}

.option {
  padding: 8px;
  cursor: pointer;
  width: max-content;
  margin-right: 20px;
  font-size: 14px;
}

.option:hover {
  background-color: #011d70;
  color: white;
}

/* Custom scrollbar */
.options::-webkit-scrollbar {
  width: 5px;
}

.options::-webkit-scrollbar-track {
  background-color: #011d70;
  border-radius: 5px;
}
.green {
  color: "green";
}

.options::-webkit-scrollbar-thumb {
  background-color: #6083f7;
  border-radius: 10px;
}


.thank-you{ padding:20px 0px; text-align: center; width: 100%;}
.thank-you img{  max-width: 80%; height: auto;;}