.payments_heading{
margin-inline: 3%;
  margin-bottom: 3%;
  font-size: 30px;
  
  margin-top: 2%;}

  .user-profile-cell {
    display: flex;
    align-items: center;
}
  .paymentstable-container{
    /* overflow: hidden; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #011D6E;
    border-radius: 20px;
    opacity: 1;
    border-radius: 20px;
    margin-bottom: 5%;
  }
  .payments-table{
    width: 100%;
    border-collapse: collapse;
    height: 100%;
    font-size: 16px;
    
  }
  td {
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
.status-completed {
    background-color: #011D70;
    color: white;
    /* padding: 5px 10px; */
    border-radius: 15px;
    padding:3%;
    display: inline-block;
    min-height:20px ;
   
    font-size: 14px;
    
    text-align: center;
    width: 100px;
  }
  
  .status-canceled {
    background-color: #EC595B;
    color: white;
    padding:3%;
    font-size: 14px;
    
    border-radius: 15px;
    display: inline-block;
    min-height:20px ;
    font-size: 14px;
    
    width: 100px;
    text-align: center;
   
  }

  