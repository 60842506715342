/* Add these styles to your CSS file or component */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F5FAFE 0% 0% no-repeat padding-box;/* Semi-transparent black overlay */
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 1000; /* Ensure it's above other content */
  }
  
  .modal {
    background: #fff; /* White background for the modal */
    padding: 20px;
    border-radius: 8px;
    max-width: 80%; /* Adjust as needed */
    max-height: 80%; /* Adjust as needed */
    
    position: relative;
    margin-inline:30%;
  /* Ensure it's above the overlay */
    
  

  }
  .modal_matter{
   padding:20px;
   
overflow-y:scroll;
   width:90%; /* Adjust as needed */
   height: 60vh; 
 
  }
  /* Scrollbar.css */
.modal_matter::-webkit-scrollbar {
    width: 5px;
 
  }
  
  .modal_matter::-webkit-scrollbar-track {
    background-color: #B2C4FF; /* Background color of the scrollbar track */
    border-radius: 10px; /* Border radius for the track */
  
    
  }
  
  .modal_matter::-webkit-scrollbar-thumb {
    background-color: #6083F7; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Border radius for the thumb */
  }
  
  .modal_matter::-webkit-scrollbar-thumb:hover {
    background-color: #006699; /* Color of the scrollbar thumb on hover */
  }
  
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #333; /* Adjust as needed */
  }
  