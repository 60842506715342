.User_Remarks {

   
    margin-top: 2rem; 
 
    padding: 1.5rem; /* Equivalent to p-6 */
   
    width: 100%;

    
  }
  .Remarks_container{
    margin-top: 2rem; 

 
    background-color: white; /* Equivalent to bg-white */
    border-radius: 20px; /* Equivalent to rounded-lg */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Equivalent to shadow */
   
    /* border:1px solid black; */
    width: 100%;
  }
  
  .User_Remarks_header {
    display: flex;
    justify-content: space-between; /* Equivalent to justify-between */
    align-items: center; /* Equivalent to items-center */
    margin-bottom: 1.5rem; /* Equivalent to mb-6 */
    
    font-size: 30px;
  }
  
  .User_Remarks_title {
    font-size: 1.875rem; /* Equivalent to text-3xl */
    font-weight: 600; /* Equivalent to font-semibold */
  }
  
  .User_Remarks_cancel-btn {
    background-color:white; /* Equivalent to bg-gray-100 */
    color: black; /* Equivalent to text-gray-700 */
    border: 1px solid black;
    border-radius: 30px;
    padding:1%;
    width: 8%;
  }
  
  .User_Remarks_table {
    width: 100%; /* Equivalent to w-full */
    border-collapse: collapse;
    border: 1px solid #e5e7eb; /* Equivalent to border */
    border-radius: 0.375rem; /* Equivalent to rounded-md */
    border-right: none;
    border-top: none;
    border-left: none;
   
  
  }
  
  .User_Remarks.th,
  td {
    padding: 1rem; 
    text-align: left;
     border-right: none;
 
    
  }
  .User_Remarks
  {
    td{
    padding-left: 5%;
    
    font-size: 16px;

 
    }
    th{
      
      
      padding-left: 5%;
      padding-bottom: 15px;
      padding-top: 15px;
      width: max-content;
      font-size: 16px;
      font-family: "Poppins", sans-serif !important;
    font-weight: 500;
 
    }
  }
 
  
  .User_Remarks_user-info {
    display: flex;
    align-items: center; /* Equivalent to items-center */
    gap: 0.75rem; /* Equivalent to space-x-3 */
  }
  
  .User_Remarks_avatar {
    width: 2rem; /* Equivalent to w-8 */
    height: 2rem; /* Equivalent to h-8 */
    /* background-color: #d1d5db; Equivalent to bg-gray-300 */
    border-radius: 9999px; /* Equivalent to rounded-full */
    display: flex;
    align-items: center; /* Equivalent to items-center */
    justify-content: center; /* Equivalent to justify-center */
  }
  
 

  