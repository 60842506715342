.footer {
}
.footer_routes {
  display: flex;
  flex-direction: row;
  margin-inline: 10%;

  align-items: flex-start;
}
.footer_other {
  display: flex;
  flex-direction: column;
  p {
    cursor: pointer;
    margin-bottom: 15%;
  }
}
.footer_p3 {
  width: max-content;
  margin-bottom: 10%;
}
.footer_quick {
  display: flex;
  flex-direction: column;
}
.footer_p2 {
  font-size: 14px;
  margin-bottom: 20%;
  width: max-content;
}
.footer_p1 {
  font-size: 21px;
  margin-bottom: 20%;
  width: max-content;
}
.footer_categories {
  margin-inline: 20%;
  width: max-content;
}

.footer-main-wrap {
  display: flex;
  width: 100%;
}
.footer-main-1 {
  width: 25%;
}
.footer-main-2 {
  flex: 1;
  padding: 0 5%;
}
.footer-main-3 {
  width: 25%;
}

.footer-main-wrap ul {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0px 0px 0px 5px;
  padding: 0px;
  list-style: none;
}
.footer-main-wrap ul li {
  width: 100%;
  padding: 10px 0px;
}
.footer-main-wrap ul li a {
  color: #fff;
  display: block;
}
.footer-main-2 ul li {
  width: 46% !important;
}
.footer-main-wrap h3 {
  color: #fff;
  font-weight: 400;
  margin: 0px 0px 10px 0px;
  padding: 0px;
  font-size: 21px;
}

.footer-copy-wrap {
  display: flex;
  width: 100%;
  border-top: 1px solid #fff;
  padding: 10px 5px;
  color: #fff;
  margin: 14px 0px 0px 0px;
}
.footer-copy-1 {
  flex: 1;
}
.footer-copy-2 {
}
@media screen and (max-width: 830px) {
  .footer-main-wrap {
    flex-wrap: wrap;
  }
  .footer-main-1 {
    order: 1;
    width: 45%;
  }
  .footer-main-3 {
    order: 2;
    width: 45%;
  }
  .footer-main-2 {
    order: 3;
    width: 100%;
    padding: 20px 0%;
  }
}

@media screen and (max-width: 600px) {
  .footer-main-2 ul li {
    width: 100% !important;
  }
  .footer-copy-wrap {
    flex-wrap: wrap;
    text-align: center;
  }
  .footer-copy-1 {
    width: 100%;
    text-align: center;
  }
  .footer-copy-2 {
    width: 100%;
    text-align: center;
    padding: 20px 0px;
  }
}
