.agents_grid {
  height: auto;
  width: 100%;
  margin-bottom: 3%;
}
.agents_heading {
  font-size: 30px;

  padding: 1%;
  margin-inline: 7%;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  /* padding: 16px; */
  margin-inline: 7%;
}

.grid-item1 {
  color: #1e1d1d;
  /* padding: 20px; */
  text-align: center;
  border-radius: 8px;
  min-width: 200px;
}
.image-container {
  height: 200px; /* Set a fixed height for the image container */
  overflow: hidden;
  border-radius: 10px;
}

.img {
  height: 200px;
  object-fit: cover;
  border-radius: 10px; /* Adjust as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.word-container {
  margin-top: 10px;
  padding: 2%;
}
.agent_name {
  font-size: 21px;
  font-weight:500;
}
.agent_member {
  font-size: 14px;
  color: grey;
}
.agent_price {
  font-size: 16px;
}
.agent_consult {
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
    font-weight: 500;
    font-style: italic;
  text-decoration: underline;
  color: black;
}
.custom-line {
  border: 1px solid grey; /* Customize border color, width, etc. */
  margin-inline: 5%;
  align-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
}
.works {
  width: 100%;

  padding: 1%;
}
.works_heading1 {
  text-align: center;

  font-size: 3em;
}
/* MyComponent.css */
.works_grid-container {
  display: grid;

  width: 100%;
  grid-template-rows: repeat(
    auto-fill,
    minmax(auto-fill, 1fr)
  ); /* Dynamic columns with a minimum of 300px width */
  gap: 10px; /* Adjust the gap between grid items */
}

.works_grid-item {
  display: flex;
  flex-direction: row;
  margin-inline: 7%;
  align-items: center;
}

.works_grid-item:nth-child(even) {
  flex-direction: row-reverse; /* Reverse the order of items in every other row */
}
.works_img_container {
  width: 50%;
  margin: 0px;
  padding: 0px;
  height: 100%;
}

.works_img {
  width: 100%; /* Full width for the image */
  object-fit: cover;
  height: 100%;

  /* max-width: 300px; Limit the max width of the image */
}

.works_matter {
  width: 50%;
  padding: 2%;
  margin-top: 8%;

  /* Full width for the text */
  /* Add margin between image and text */
}
.works_topic {
  font-weight:500;
  font-size: 2em;
}
.works_info {
  font-size: 1em;
}
@media (max-width: 768px) {
  .works_heading1 {
    text-align: center;
    font-size:2em;
}
.agents_heading p{ font-size: 22px;;}
  .works_grid-item,
  .works_grid-item:nth-child(even) {
    flex-direction: column;
  }

  .works_matter {
    width: 100%;
    margin-top: 4%;
  }

  .works_topic {
    font-size: 1.5em;
  }

  .works_info {
    font-size: 0.9em;
  }
}
