.header {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #293073;
  background-color: white;
  /* position: fixed;
top: 0;
left: 0;
width: 100%;
z-index: 1100; */
  a.active {
    color: #6083f7;
  }
}

.brand {
  justify-content: center;
  margin-right: 5%;
  margin-left: 5%;
}
header a,
header select {
  margin-right: 15px;
  border: none;
  color: #000;
  display: inline-block;
}

.menu {
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 10%;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.header .mobile-menu-icon {
  display: none;
}

.header img {
  height: auto;
  max-width: 100%;
}

.header button {
  width: 128px;
  height: 44px;
  border-radius: 10px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  background: #6083f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 27px;
  opacity: 1;
  border: none;
}

@media (max-width: 767px) {
  .header {
    padding: 0 20px;
  }
  .header .menu select {
    width: auto; /* Set width to auto or a specific value */
  }
  .header .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    z-index: 2;
  }
  .header {
    padding: 0 20px;
  }

  .header .mobile-menu-icon {
    display: block;
  }
  .header .menu.open {
    display: flex;
  }

  .header .menu a {
    margin: 10px 0;
  }

  .header button {
    width: 100%;
    margin-top: 10px;
  }
}
