.Agent_Remarks {
  padding: 2%; /* Equivalent to p-6 */
}
.AgentsRemarks_container {
  margin-top: 1%;
  margin-inline: 2%;
  width: 100%;
  background-color: white; /* Equivalent to bg-white */
  box-shadow: 0px 0px 25px #00000026;
  border-radius: 20px;
  opacity: 1;
  /* border:1px solid black; */
}

.Agent_Remarks_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Equivalent to justify-between */

  align-items: center; /* Equivalent to items-center */
  /* Equivalent to mb-6 */
  margin-inline: 2%;
  width: 100%;
}

.Agent_Remarks_title {
  font-size: 30px;
}

.Agent_Remarks_cancel-btn {
  background-color: white; /* Equivalent to bg-gray-100 */
  /* Equivalent to text-gray-700 */
  color: black !important;

  padding: 1%;
  width: 8%;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #333333;
  border-radius: 27px;
  opacity: 1;
}

.Agent_Remarks_table {
  width: 100%; /* Equivalent to w-full */
  border-collapse: collapse;
  border: 1px solid #e5e7eb; /* Equivalent to border */
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  border-right: none;
  border-top: none;
  border-left: none;
}

.Agent_Remarks.th,
td {
  padding: 1rem;
  text-align: left;
  border-right: none;

  font-size: 16px;
}
.Agent_Remarks {
  td {
    padding-left: 2%;
  }
  th {
    padding-left: 2%;
    padding-bottom: 15px;
    padding-top: 15px;
    width: max-content;
    font-size: 16px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 500;
  }
}

.Agent_Remarks_user-info {
  display: flex;
  align-items: center; /* Equivalent to items-center */
  gap: 0.75rem; /* Equivalent to space-x-3 */
}

.Agent_Remarks_avatar {
  width: 2rem; /* Equivalent to w-8 */
  height: 2rem; /* Equivalent to h-8 */
  background-color: white; /* Equivalent to bg-gray-300 */
  border-radius: 9999px; /* Equivalent to rounded-full */
  display: flex;
  align-items: center; /* Equivalent to items-center */
  justify-content: center; /* Equivalent to justify-center */
}

.Agent_Remarks_remarks {
  margin: 1rem;
  /* Equivalent to mt-6 */
}

.Agent_Remarks_remarks-input {
  width: 100%; /* Equivalent to w-full */
  padding: 1rem; /* Equivalent to p-4 */
  border: 1px solid #cccccc;
  border-radius: 5px; /* Equivalent to rounded-md */
  margin:0 auto;
  min-height: 15vh; resize: none;
}

.Agent_Remarks_submit-btn {
  display: flex;
  justify-content: start; /* Equivalent to justify-center */
  /* Equivalent to mt-4 */
  margin-top: 2%;
}
